import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-id-button',
  templateUrl: './app-id-button.component.html',
  styleUrls: ['./app-id-button.component.scss'],
})
export class AppIdButtonComponent implements OnInit {

  @Input() textHeader: string = '';
  @Input() image: string = '';
  @Input() entrada: string = '';
  @Input() _placeholder: string = '';
  @Input() textDni: string = '';
  @Input() textNif: string = '';
  @Input() textPasaporte: string = '';
  @Input() _placeholderIf: string = '';

  showPasaporte:boolean = false;

  constructor() { }

  ngOnInit() {}

  mostarPasaporte(){
    this.showPasaporte =  true;
  }

}
