import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  { path: "", redirectTo: "cover-page", pathMatch: "full" },
  { path: "cover-page", loadChildren: () => import("./pages/cover-page/cover-page.module").then((m) => m.CoverPagePageModule) },
  { path: "logout", redirectTo: "login", pathMatch: "full" },
  { path: "login", loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule) },
  { path: "register", loadChildren: () => import("./pages/register/register.module").then((m) => m.RegisterPageModule) },
  { path: "professional-register", loadChildren: () => import("./pages/professional-register/professional-register.module").then((m) => m.ProfessionalRegisterPageModule) },
  { path: "home", canActivate: [AuthGuard], loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule) },
  { path: "details", canActivate: [AuthGuard], loadChildren: () => import("./pages/details/details.module").then((m) => m.DetailsPageModule) },
  { path: "profile", canActivate: [AuthGuard], loadChildren: () => import("./pages/profile/profile.module").then((m) => m.ProfilePageModule) },
  { path: "forgot-password", loadChildren: () => import("./pages/forgot-password/forgot-password.module").then((m) => m.ForgotPasswordPageModule) },
  { path: "chats", canActivate: [AuthGuard], loadChildren: () => import("./pages/chats/chats.module").then((m) => m.ChatsPageModule) },
  { path: "interior-chat/:id_chat/:nombre_chat/:ultimo_mensaje", canActivate: [AuthGuard], loadChildren: () => import("./pages/interior-chat/interior-chat.module").then((m) => m.InteriorChatPageModule) },
  { path: "tabs", canActivate: [AuthGuard], loadChildren: () => import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule) },
  // { path: "professional-register", loadChildren: "./pages/professional-register/professional-register.module#ProfessionalRegisterPageModule" },
  { path: "contact", canActivate: [AuthGuard], loadChildren: () => import("./pages/contact/contact.module").then((m) => m.ContactPageModule) },
  { path: "edit-profile", canActivate: [AuthGuard], loadChildren: () => import("./pages/edit-profile/edit-profile.module").then((m) => m.EditProfilePageModule) },
  { path: "public-profile", canActivate: [AuthGuard], loadChildren: () => import("./pages/public-profile/public-profile.module").then((m) => m.PublicProfilePageModule) },
  { path: "my-requests", canActivate: [AuthGuard], loadChildren: () => import("./pages/my-requests/my-requests.module").then((m) => m.MyRequestsPageModule) },
  { path: "score-user", canActivate: [AuthGuard], loadChildren: () => import("./pages/score-user/score-user.module").then((m) => m.ScoreUserPageModule) },
  { path: "add-score-user/:valoracionPendiente_id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/add-score-user/add-score-user.module").then((m) => m.AddScoreUserPageModule) },
  { path: "notification-user", canActivate: [AuthGuard], loadChildren: () => import("./pages/notification-user/notification-user.module").then((m) => m.NotificationUserPageModule) },
  { path: "add-service/:id?/:categoria_id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/add-service/add-service.module").then((m) => m.AddServicePageModule) },
  { path: "detail-service/:table?/:id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/detail-service/detail-service.module").then((m) => m.DetailServicePageModule) },
  { path: "tabs-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/tabs-professional/tabs-professional.module").then((m) => m.TabsProfessionalPageModule) },
  { path: "home-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/home-professional/home-professional.module").then((m) => m.HomeProfessionalPageModule) },
  { path: "add-score-user-value/:valoracionPendiente_id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/add-score-user-value/add-score-user-value.module").then((m) => m.AddScoreUserValuePageModule) },
  { path: "payment-method-page/:service_id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/payment-method-page/payment-method-page.module").then((m) => m.PaymentMethodPagePageModule) },
  { path: "professional-profile", canActivate: [AuthGuard], loadChildren: () => import("./pages/professional-profile/professional-profile.module").then((m) => m.ProfessionalProfilePageModule) },
  { path: "professional-public-profile", canActivate: [AuthGuard], loadChildren: () => import("./pages/professional-public-profile/professional-public-profile.module").then((m) => m.ProfessionalPublicProfilePageModule) },
  { path: "professional-my-requests", canActivate: [AuthGuard], loadChildren: () => import("./pages/professional-my-requests/professional-my-requests.module").then((m) => m.ProfessionalMyRequestsPageModule) },
  { path: "professional-edit-profile", canActivate: [AuthGuard], loadChildren: () => import("./pages/professional-edit-profile/professional-edit-profile.module").then((m) => m.ProfessionalEditProfilePageModule) },
  { path: "claim-service", canActivate: [AuthGuard], loadChildren: () => import("./pages/claim-service/claim-service.module").then((m) => m.ClaimServicePageModule) },
  { path: "notification-user-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/notification-user-professional/notification-user-professional.module").then((m) => m.NotificationUserProfessionalPageModule) },
  { path: "contact-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/contact-professional/contact-professional.module").then((m) => m.ContactProfessionalPageModule) },
  { path: "score-user-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/score-user-professional/score-user-professional.module").then((m) => m.ScoreUserProfessionalPageModule) },
  { path: "add-score-user-professional/:valoracionPendiente_id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/add-score-user-professional/add-score-user-professional.module").then((m) => m.AddScoreUserProfessionalPageModule) },
  { path: "add-score-user-professional-value/:valoracionPendiente_id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/add-score-user-professional-value/add-score-user-professional-value.module").then((m) => m.AddScoreUserProfessionalValuePageModule) },
  { path: "requests-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/requests-professional/requests-professional.module").then((m) => m.RequestsProfessionalPageModule) },
  { path: "details-service-professional/:id?", canActivate: [AuthGuard], loadChildren: () => import("./pages/details-service-professional/details-service-professional.module").then((m) => m.DetailsServiceProfessionalPageModule) },
  { path: "services-list-professional", canActivate: [AuthGuard], loadChildren: () => import("./pages/services-list-professional/services-list-professional.module").then((m) => m.ServicesListProfessionalPageModule) },
  { path: 'claim-service-professional/:id?', canActivate: [AuthGuard], loadChildren: () => import("./pages/claim-service-professional/claim-service-professional.module").then((m) => m.ClaimServiceProfessionalPageModule) },
  { path: 'my-request-professional/:service_id?/:idChat?', canActivate: [AuthGuard], loadChildren: () => import("./pages/my-request-professional/my-request-professional.module").then((m) => m.MyRequestProfessionalPageModule) },
  { path: 'modal-valoraciones', loadChildren: './pages/modal-valoraciones/modal-valoraciones.module#ModalValoracionesPageModule' },
  { path: "text", loadChildren: () => import("./pages/text/text.module").then((m) => m.TextPageModule) },
  { path: 'rnutricion', loadChildren: './pages/rnutricion/rnutricion.module#RnutricionPageModule' },
  { path: 'cleverpremium', loadChildren: './pages/cleverpremium/cleverpremium.module#CleverpremiumPageModule' },
  { path: 'perfil', loadChildren: './pages/perfil/perfil.module#PerfilPageModule' },
  { path: 'perfilp', loadChildren: './pages/perfilp/perfilp.module#PerfilpPageModule' },
  { path: 'qrcode', loadChildren: './pages/qrcode/qrcode.module#QRCODEPageModule' },

  { path: 'suscripciones', loadChildren: () => import('./pages/suscripciones/suscripciones.module').then(m => m.SuscripcionesPageModule) },
  { path: 'misuscripcion', loadChildren: () => import('./pages/misuscripcion/misuscripcion.module').then(m => m.MisuscripcionPageModule) },
  { path: 'modal-reportar', loadChildren: () => import('./pages/modal-reportar/modal-reportar.module').then(m => m.ModalReportarPageModule) },
  { path: 'users-blocked', loadChildren: () => import('./pages/users-blocked/users-blocked.module').then(m => m.UsersBlockedPageModule) },
  { path: 'modal-publicidad', loadChildren: () => import('./pages/modal-publicidad/modal-publicidad.module').then(m => m.ModalPublicidadPageModule) },








];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
