import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { codeErrors } from 'src/app/utils/utils';

import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { MultipleDocumentsPicker } from '@ionic-native/multiple-document-picker/ngx';

@Component({
  selector: 'app-modal-reportar',
  templateUrl: './modal-reportar.page.html',
  styleUrls: ['./modal-reportar.page.scss'],
})
export class ModalReportarPage implements OnInit {

  public form: FormGroup;
  public isLoading: boolean = true;
  reportIssues: any;
  idUser: any;
  user: User;
  public indexSelected: number[] = [];
  public report: boolean = true;
  public files: any[] = [];
  public filesData: any[] = [];
  usuario_id;



  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private utilities: UtilitiesService,
    public auth: AuthenticationService,
    private alertController: AlertController,
    private ngZone: NgZone,
    private modalCtrl: ModalController,
    private multipleDocument: MultipleDocumentsPicker,
    private file: File,
    private filepath: FilePath,
  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      generic_report_issue_id: ['', Validators.required],
      user_reported_id: ['', Validators.required],
      documentos: [''],

    });
    this.getIssues();

    this.idUser = this.apiService.userReportedId;
    this.form.patchValue({
      user_reported_id: this.idUser
    })
    console.log("usuario id", this.idUser);

  }

  public getIssues() {
    this.apiService.getEntity('report-issues').subscribe((reportIssues) => {
      this.reportIssues = reportIssues;
      console.log("report issues", reportIssues);

    }, (error) => {
      this.utilities.showToast(codeErrors(error));
    });
  }


  public getUser() {
    this.isLoading = true;
    this.apiService.getEntity('user', this.idUser).subscribe((user: User) => {
      this.user = user;
      this.isLoading = false;



      console.log("usuario seleccionado para ver si perfil", this.user);

    }, (error) => {
      this.isLoading = false;

      this.utilities.showToast(codeErrors(error));
    });
  }

  public getYears(birth) {
    return moment().diff(birth, 'years');
  }

  public isColor(index) {
    return this.indexSelected.find(element => element == index);
  }



  public async reportarUsuario() {
    const alert = await this.alertController.create({
      header: '',
      message: '',
      buttons: [
        {
          text: 'Reportar',
          handler: () => {
            this.ngZone.run(() => {
              this.report = !this.report;
            })
          }
        },
        {
          text: 'Bloquear',
          handler: () => {
            this.utilities.showLoading('Bloqueando...');
            this.apiService.addEntity('blocked-users', { user_id: this.idUser }).subscribe((response) => {
              console.log(response);
              // this.apiService.partiesChanges.next();
              this.utilities.dismissLoading();
              this.utilities.showToast('Usuario bloqueado correctamente');

            }, (error) => {
              console.log(error);
              this.utilities.showToast(codeErrors(error));

            })
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true

    });

  }

  public getFiles() {



    this.multipleDocument.pick(2).then((files) => {
      console.log("files", JSON.parse(files));




      this.files = JSON.parse(files);



      this.files.forEach(async file => {
        // Try to obtain local device URL from previous file

        console.log(file);

        let filePath = "file://" + file.uri;
        // this.filepath.resolveNativePath(filePath)
        //   .then(async (filePath2) => {
        //     console.log("base 64?", filePath2);
        //     filePath = filePath2;



        //     try {
        //       filePath = decodeURI(filePath);

        //       console.log("filepath", filePath);

        //     }
        //     catch (err) {
        //       console.log('error 2do catch');
        //       console.log(err);
        //       console.log('-------------------------');
        //       this.utilities.showAlert('Error al intentar obtener URL local de archivo', 'Hubo un error al intentar obtener la URL local del archivo. Inténtalo de nuevo más tarde');
        //       return;
        //     }

        //     // Convert to base64
        //     let filename = filePath.substring(filePath.lastIndexOf("/") + 1);
        //     let path = filePath.substring(0, filePath.lastIndexOf("/") + 1);

        //     console.log("path", path);
        //     console.log("filename", filename);

        //     let base64string;

        //     // try {
        //     //   base64string = await this.file.readAsDataURL(path, filename);
        //     // }
        //     // catch (err) {
        //     //   console.log('error 3er catch');
        //     //   console.log(err);
        //     //   console.log('-------------------------');
        //     //   this.utilities.showAlert('Error al intentar convertir archivo', 'Hubo un error al intentar convertir el archivo para su subida. Inténtalo de nuevo más tarde');
        //     //   return;
        //     // }

        //     // let documento: any = {
        //     //   name: filename,
        //     //   extension: filename.substring(filename.lastIndexOf(".") + 1),
        //     //   //type: tipo,
        //     //   file: base64string,
        //     //   created: moment().format()
        //     // };

        //     // console.log(documento);

        //     // this.filesData.push(documento);


        //   })
        //   .catch(err => console.log(err));


        let documento: any = {
          name: file.name,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
          //type: tipo,
          file: file.base64,
          created: moment().format()
        };

        console.log(documento);

        this.filesData.push(documento);

      });


      this.form.patchValue({
        documentos: this.filesData,
      });
    }, (error) => {
      console.log("error multiple picker", error);

      this.utilities.showToast(codeErrors(error));
    });
  }

  public submitForm(): void {
    console.log("enviar reporte", this.form.value);


    this.apiService.addEntity('reports', this.form.value).subscribe((response) => {
      console.log("report correct", response);
      this.utilities.showToast('Reporte enviado correctamente');
    }, (error) => {
      this.utilities.showToast(codeErrors(error));
    });


  }


}
