import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-chat-header',
  templateUrl: './app-chat-header.component.html',
  styleUrls: ['./app-chat-header.component.scss'],
})
export class AppChatHeaderComponent {

  @Input() chatName: string;
  @Input() lastMessage: string;
  @Input() img: string;
  @Input() id: string;
  @Input() role: string;


  constructor(
    private navCtrl: NavController
  ) { }

  formatDate(date) {
    return moment(date).format('H:m');
  }

  redirect() {
    console.log(this.role);

    if (this.role == "4") {
      this.navCtrl.navigateForward('professional-public-profile', {
        state: {
          idUser: this.id
        }
      })
    } else {
      this.navCtrl.navigateForward('public-profile', {
        state: {
          idUser: this.id
        }
      })
    }
  }

}
