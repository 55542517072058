import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { codeErrors } from 'src/app/utils/utils';

@Component({
  selector: 'app-chat-group',
  templateUrl: './app-chat-group.component.html',
  styleUrls: ['./app-chat-group.component.scss'],
})
export class AppChatGroupComponent {

  @Input() icon: string;
  @Input() redirection: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() lastMessage: string;
  @Input() newMessages: number;
  @Input() slidingOptionsSide: string;
  @Input() slidingOptionsColor: string;
  @Input() slidingOptionsIcon: string;
  @Input() id: number;
  @Input() oculto: boolean;

  constructor(
    private apiService: ApiService,
    private utilities: UtilitiesService,
  ) { }

  formatDate(date) {
    return moment(date).format('H:m');
  }

  delete() {
    console.log('Borra el chat ', this.id);
    this.apiService.deleteEntity('chats', this.id).subscribe((element) => {
      this.utilities.showToast('Chat borrado correctamente');
      this.apiService.chatChanges.next();
    }, (error) => {
      this.utilities.showToast(codeErrors(error));
    })
  }

}
