/**
 * Variables globales de la aplicación en local
 */


export const environment = {
  production: false,

  // URL del dominio asociado a la aplicación, para comprobar si está o no suspendido
  domainUrl: 'https://development.xerintel.net/',

  // URL del endpoint de la api de pruebas
  archivos: 'https://app.squidfix.com/devxerintel/storage/',
  apiUrl: 'https://app.squidfix.com/devxerintel/api/auth/',
  // apiUrl: 'http://localhost/juan-felix/juanfelix-laravel/public/api/auth/',
  // apiUrl: 'http://localhost/juanfelix-laravel/public/api/auth/',

  /* apiUrl: 'http://localhost/laravel-base-api/public/api/auth/', */

  //Sender id para las notificaciones push
  senderID: '979952093093',

  //Clave de stripe
  // stripePublishableKey: 'pk_test_51L2XrALgLBOeOj7wuJNo7UrAIjjfsL3LO4y78j4c5DNeFQM4xorhoB1pDKbbDOAvYx9ewTvvGYGopwKsQwUBFQEo00P49h4wEm'
  stripePublishableKey: 'pk_live_51L2XrALgLBOeOj7wrWlaLSUUuKUbnng6Dw6A8YnXzdbIhAIDDaMwMQRu0rmx7ZmTFV6tVPZDmSsITMpAMLbNw48x003pcLpDOc'
};
