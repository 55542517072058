import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-text',
  templateUrl: './app-text.component.html',
  styleUrls: ['./app-text.component.scss'],
})
export class AppTextComponent implements OnInit {

  @Input() textHeader: string = '';
  @Input() image: string = '';
  @Input() textBody: string = '';

  constructor() { }

  ngOnInit() {}

}
