import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-submit-button',
  templateUrl: './app-submit-button.component.html',
  styleUrls: ['./app-submit-button.component.scss'],
})
export class AppSubmitButtonComponent implements OnInit {
  
  @Input() textHeader: string = '';
  @Input() image: string = '';
  @Input() entrada: string = '';
  @Input() _placeholder: string = '';
 
  constructor() { }

  ngOnInit() {}

}
