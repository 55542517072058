import { Component, NgZone, OnInit } from '@angular/core';

import { Platform, Events, NavController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api.service';
import { User } from './models/User';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { environment } from "../environments/environment";
import { Stripe } from '@ionic-native/stripe/ngx';
import { AuthenticationService } from './services/authentication.service';
import { Storage } from '@ionic/storage';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilitiesService } from './services/utilities.service';
import { NotificacionesNuevasService } from './services/notificaciones-nuevas.service';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { FCMNotification } from './models/FCMNotification';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  user: User;
  public isLoading: boolean = true;
  public appPages = [
    {
      title: 'Inicio',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Perfil',
      url: '/profile',
      icon: 'person'
    },
    {
      title: 'Chats',
      url: '/chats',
      icon: 'chatboxes'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private push: Push,
    private events: Events,
    private stripe: Stripe,
    private auth: AuthenticationService,
    private navCtrl: NavController,
    private storage: Storage,
    private utilities: UtilitiesService,
    private ngZone: NgZone,
    private toastController: ToastController,
    private notifications: NotificacionesNuevasService,
    private locationAccuracy: LocationAccuracy,
    public diagnostic: Diagnostic,
    public firebase: FirebaseMessaging,
    private router: Router,
  ) {
  }

  /**
   * Nos suscribimos a los cambios dle perfil
   */
  public ngOnInit(): void {

    this.auth.authenticationState.subscribe(token => {
      if (token != 'logout' && token != '') {
        this.prepararStripe();
        this.apiService.setTokenToHeaders(token);
        this.apiService.setTokenStorage(token);
        this.notifications.getMensajesNuevos();
        // this.navCtrl.navigateRoot('tabs').then(() => {
        //   this.isLoading = false;
        // });
        this.apiService.getEntity('user').subscribe((user) => {
          console.log(user);
          // Compruebo la plataforma para no mostrar el tab
          // if (!this.platform.is('android') && !this.platform.is('ios')) {
          //   if (user.role_id == '4') {
          //     this.navCtrl.navigateRoot('home-professional').then(() => {
          //       this.pushNotifications2();
          //       this.comprobarSuscription();
          //       this.isLoading = false;
          //     });
          //   } else {
          //     this.navCtrl.navigateRoot('home').then(() => {
          //       this.pushNotifications2();

          //       this.isLoading = false;
          //     });
          //   }
          // } else {
          if (user.role_id == '4') {
            this.navCtrl.navigateRoot('tabs').then(() => {
              this.pushNotifications2();
              this.comprobarSuscription();
              this.isLoading = false;
            });
          } else if (user.role_id == '5') {
            console.log('invitado');
            this.navCtrl.navigateRoot('tabs').then(() => {
              this.pushNotifications2();
              this.comprobarSuscription();
              this.isLoading = false;
            });

          } else {
            this.navCtrl.navigateRoot('tabs').then(() => {
              this.pushNotifications2();

              this.isLoading = false;
            });
          }
          // }


        })

        this.checkGPS();

      } else if (token == 'logout') {
        this.apiService.removeTokenToHeaders();
        this.navCtrl.navigateRoot('cover-page').then(() => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
        console.log("primera vez");
      }

      // IMPORTANTE: para comprobar si la app está o no suspendida, debe ponerse el dominio en la propiedad "domainUrl" del archivo "src/environments/environment.ts"
      this.checkIfAppIsSuspended();
    });

    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        this.statusBar.styleBlackOpaque();
        this.splashScreen.hide();
      });
    }

    this.apiService.userChanges.subscribe((user: User) => {
      this.user = user;
    });
  }

  /**
   * Comprueba si el dominio de la aplicación está suspendido. Si lo está, muestra un mensaje de aviso al usuario
   */
  public checkIfAppIsSuspended() {
    this.apiService.checkAppDomain().subscribe(async (response) => {
      // no hacemos nada, ya que el dominio de la aplicación estaría activado
    },
      async (errorResponse: HttpErrorResponse) => {
        if (errorResponse.status == 0 || errorResponse.status == 403) {
          this.utilities.showAlert('Esta app no ha sido renovada', 'Si usted es el propietario, por favor hable con nosotros en el 956 105 343 para renovar el servicio o contacte con facturacion@xerintel.es', false);
        }
      })
  }


  /**
   * Comprueba si el usuario tiene suscripcion
   */
  public comprobarSuscription() {
    this.apiService.getEntity('subscription-trial').subscribe((element) => {
      console.log(element);

      if (element[0] == 0) {
        this.navCtrl.navigateRoot('/suscripciones');
        this.utilities.showAlert('El periodo de prueba ha finalizado.', 'Para seguir usando la aplicación elija una suscripción de pago.', false);
        this.apiService.plan_id = element[1];
        this.apiService.trial_end = element[0];
      } else {
        this.apiService.plan_id = element[1];
        this.apiService.trial_end = element[0];
      }
    })


  }


  /**
   * Configuración de las notificación push
   */
  public async pushNotifications2() {

    await this.firebase.requestPermission({ forceShow: false });

    //await cordova.plugins.firebase.messaging.requestPermission({ forceShow: false });

    let token;

    try {
      this.firebase.subscribe('messages')
      //token = await cordova.plugins.firebase.messaging.getToken();
      token = await this.firebase.getToken();


    }
    catch (err) {
      console.log('err obtener token firebase');
      console.log(err);
      return;
    }

    console.log('token = ');
    console.log(token);

    this.firebase.setBadge(0);


    if (token) {
      this.apiService.guardarTokenDeRegistro(token).subscribe((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
    }



    this.firebase.onBackgroundMessage().subscribe((payload: FCMNotification) => {
      console.log("New background FCM message: ", payload);
      if (this.utilities.getPlatform() === 'ios') {
        payload['apiData'] = payload['gcm.notification.apiData'];
        payload['title'] = payload.aps.alert.title;
        payload['message'] = payload.aps.alert.body;
      }
      payload.apiData = JSON.parse(payload.apiData);
      console.log(payload.apiData);



      let urlArray = payload.apiData.url.split("/");
      console.log("ARRAY URL", urlArray);
      //this.apiService.chatChanges.next();





      // this.apiService.addEntity('test-notification', { log: payload }).subscribe((response) => {
      //   console.log("response", response);

      // }, (error) => {
      //   console.log(error);

      // });


      if (payload.from) {
        console.log("notification click", payload);


        if (payload.apiData.url == '/notification-user') {
          console.log("LA RUTA DE LA NOTIFICACION LLEGADA ES NOTIFICACIONES");

          this.ngZone.run(() => {


            this.navCtrl.navigateForward(payload.apiData.url);



          });
        } else if (this.router.url.split("/").length > 1 && urlArray.length > 2 && urlArray[1] == 'interior-chat' && urlArray[2]) {

          console.log("URL ES INTERIOR CHAT");


          this.ngZone.run(() => {
            this.navCtrl.navigateForward(payload.apiData.url);


            // COMPROBAR QUE LA URL, NO ES LA DEL CHAT AL QUE HEMOS HABLADO PARA MOSTRAR NOTIFICACION

            let urlNow = this.router.url.split("/")[2];

            if (this.router.url == '/tabs/chats') {
              console.log("URL ACTUAL ES TAB CHATS");

              this.events.publish('add-mensaje', payload.apiData.mensaje);


            } else if (urlNow != payload.apiData.url.split("/")[2]) {
              console.log("URL ES LA ACTUAL, ");


            } else {
              this.events.publish('add-mensaje', payload.apiData.mensaje);
            }

          });



        }


      }






    });
    /*cordova.plugins.firebase.messaging.onBackgroundMessage(function(payladb oad) {
      console.log("New background FCM message: ", payload);
    });*/






    this.firebase.onMessage().subscribe((payload: FCMNotification) => {

      console.log("New foreground FCM message");
      console.log(payload);
      if (this.utilities.getPlatform() === 'ios') {
        payload['apiData'] = payload['gcm.notification.apiData'];
        payload['title'] = payload.aps.alert.title;
        payload['message'] = payload.aps.alert.body;
      }
      payload.apiData = JSON.parse(payload.apiData);

      console.log(payload.apiData);

      let urlArray = payload.apiData.url.split("/");
      console.log("ARRAY URL", urlArray);

      //this.apiService.chatChanges.next();


      // this.apiService.addEntity('test-notification', { log: payload }).subscribe((response) => {
      //   console.log("response", response);

      // }, (error) => {
      //   console.log(error);



      // });


      //this.utilities.showAlert(payload.title, payload.message);




      if (payload.apiData.url == '/notifications') {
        console.log("LA RUTA DE LA NOTIFICACION LLEGADA ES NOTIFICACIONES");

        this.ngZone.run(() => {
          //this.pushObject.setApplicationIconBadgeNumber(this.notificacionesNuevasService.getNotificationsItemCount() +1);

          this.showToastMessage(payload);

          //Cuando está dentro de la app muestra un toast
          /* if(notification.additionalData.foreground){    
             this.showToastMessage(notification);
           }else{
             this.navCtrl.navigateForward(payload.apiData.url);
           }*/


        });
      } else if (this.router.url.split("/").length > 1 && urlArray.length > 2 && urlArray[1] == 'interior-chat' && urlArray[2]) {

        console.log("URL ES INTERIOR CHAT");


        this.ngZone.run(() => {


          // COMPROBAR QUE LA URL, NO ES LA DEL CHAT AL QUE HEMOS HABLADO PARA MOSTRAR NOTIFICACION

          let urlNow = this.router.url.split("/")[2];

          if (this.router.url == '/tabs/chats') {
            console.log("URL ACTUAL ES TAB CHATS");

            this.events.publish('add-mensaje', payload.apiData.mensaje);


          } else if (urlNow != payload.apiData.url.split("/")[2]) {
            console.log("URL ES LA ACTUAL, ");


          } else {
            this.events.publish('add-mensaje', payload.apiData.mensaje);
          }

        });



      } else if (payload.apiData.url == '/tabs/chats') {

      }






    });

    /*
        cordova.plugins.firebase.messaging.onMessage((payload: FCMNotification) => {
    
          
          console.log("New foreground FCM message");
          console.log( payload);
          payload.apiData = JSON.parse(payload.apiData);
          
          console.log(payload.apiData);
  
          let urlArray = payload.apiData.url.split("/");
          console.log("ARRAY URL", urlArray);
        
  
          this.apiService.addEntity('test-notification', {log : payload}).subscribe((response)=>{
            console.log("response", response);
            
          }, (error)=>{
            console.log(error);
    
              
              
          });
          
    
          this.utilities.showAlert(payload.title, payload.message);
  
  
  
  
          if(payload.apiData.url == '/notifications'){
            this.ngZone.run(()=>{
              this.notificacionesNuevasService.getNotifications();
              this.notificacionesNuevasService.checkNotifications();
      
              cordova.plugins.firebase.messaging.setBadge(this.notificacionesNuevasService.getNotificationsItemCount());*/
    //this.pushObject.setApplicationIconBadgeNumber(this.notificacionesNuevasService.getNotificationsItemCount() +1);

    //Cuando está dentro de la app muestra un toast
    /* if(notification.additionalData.foreground){    
       this.showToastMessage(notification);
     }else{
       this.navCtrl.navigateForward(payload.apiData.url);
     }*/
    /*
      
            });
          }else if(this.router.url.split("/").length >1 && urlArray.length>2 && urlArray[1] == 'interior-chat' && urlArray[2]){
    
            
            this.ngZone.run(()=>{
    
    
              // COMPROBAR QUE LA URL, NO ES LA DEL CHAT AL QUE HEMOS HABLADO PARA MOSTRAR NOTIFICACION
              
              let urlNow = this.router.url.split("/")[2];
            
              if(this.router.url == '/tabs/chats'){
                this.events.publish('add-mensaje', payload.apiData.mensaje);
    
                //this.utilities.showToast('Estamos dentro de chats, ha llegado notificacion');
    
    
    
              }else if(urlNow != payload.apiData.url.split("/")[2]){
                //Cuando está dentro de la app muestra un toast
                /*if(payload.additionalData.foreground){  
                  
                  this.showToastMessage(notification);
                }else{
                  this.navCtrl.navigateForward(notification.additionalData.apiData.url);
                }*/
    /*  }else{
        this.events.publish('add-mensaje', payload.apiData.mensaje);
      }
 
    });
    
    
    
  }else if(payload.apiData.url == '/tabs/chats'){
    /*this.ngZone.run(()=>{
      this.events.publish('add-mensaje', notification.additionalData.apiData.mensaje);
 
 
      let urlNow = this.router.url;
    
      if(urlNow != '/tabs/chats'){
        //Cuando está dentro de la app muestra un toast
        if(notification.additionalData.foreground){  
          
          this.showToastMessage(notification);
        }else{
          this.navCtrl.navigateForward(notification.additionalData.apiData.url);
        }
      }else{
        // Actualizar chats para poner en orden
      }
 
      //Cuando está dentro de la app muestra un toast
      if(notification.additionalData.foreground){  
    
        this.showToastMessage(notification);
      }else{
        this.navCtrl.navigateForward(notification.additionalData.apiData.url);
      }
 
    });*/
    /*  }






    });*/

    /* const options: PushOptions = {
      android: {
        senderID: environment.senderID,
        icon: 'notification'
      },
      ios: {
        alert: 'true',
        badge: true,
        sound: 'true',
      },
      windows: {},
    }
 
    const pushObject: PushObject = this.push.init(options);
 
    pushObject.on('notification').subscribe((notification) => {
      if (notification.additionalData.foreground) {
        this.utilities.showAlert(notification.title, notification.message);
      }
    });
 
    pushObject.on('registration').subscribe((registration: any) => {
      const regId = registration.registrationId;
      this.apiService.guardarTokenDeRegistro(regId).subscribe((response) => {
        // console.log(response);
      }, (error) => {
        console.log(error);
      })
    });
 
    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error)); */
  }

  /**
   * Función para mostrar el Toast de la notificación
   */
  public async showToastMessage(notification) {
    const toast = await this.toastController.create({
      header: notification.title,
      message: notification.message,
      position: 'top',
      duration: 5000,
      animated: true,
      color: 'secondary',
      buttons: [
        {
          side: 'end',
          text: 'Abrir',
          handler: () => {
            this.ngZone.run(() => {
              if (notification.apiData.url == '/notifications') {
                this.navCtrl.navigateForward('/notification-user');
              } else if (notification.apiData.url) {
                this.navCtrl.navigateForward(notification.apiData.url);
              } else {
                this.navCtrl.navigateForward('/notification-user');
              }
            });
          }
        }, {
          text: 'X',
          //icon:'',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  /**
   * Preparamos stripe con su configuración
   */
  public prepararStripe(): void {
    this.stripe.setPublishableKey(environment.stripePublishableKey);
  }


  public checkGPS() {




    this.diagnostic.isLocationAuthorized().then((response) => {
      console.log("REQUEST LOCATION AUTORIZED SUCCESS", response);

      if (response == false) {

        this.diagnostic.requestLocationAuthorization().then((response) => {
          console.log("REQUEST LOCATION SUCCESS", response);

          if (response == 'DENIED_ONCE') {

          } else if (response == 'authorized_when_in_use' || response == 'always') {

            console.log("PERIMIDA LA UBICACIÓN");
            this.locationPermissonTrue();


          } else {

          }

        }, (error) => {
          console.log("requestLocationAuthorization ERROR", error);

        })

      } else {
        console.log("YA ESTABA PERMITIDA LA UBICACIÓN");
        this.locationPermissonTrue();
      }
    }, (error) => {
      console.log("isLocationAuthorized ERROR", error);

    })



  }


  public locationPermissonTrue() {
    this.diagnostic.isLocationEnabled().then((response) => {
      console.log("success location enabled", response);


      // Location enabled false
      if (response == false) {

        this.locationAccuracy.canRequest().then((canRequest: boolean) => {

          if (canRequest) {
            // the accuracy option will be ignored by iOS
            this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
              (response) => {
                console.log('Request successful', response);

                if (response.code == 1) {
                  console.log("SUCCESS REQUEST ACTIVATE LOCATION");

                } else { // LOCALIZACION DENEGADA POR EL USUARIO

                }
              },
              error => console.log('Error requesting location permissions', error)
            );
          }

        });
      } else {

      }


      if (this.platform.is('ios')) {
        //this.utilities.showToast('Debes habilitar los servicios de localización desde los ajustes del teléfono');
      }

    }, (error) => {
      console.log("error, not enabled", error);

    });
  }

}
