import { Component, Input, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-button-xeri",
  templateUrl: "./button-xeri.component.html",
  styleUrls: ["./button-xeri.component.scss"],
})
export class ButtonXeriComponent implements OnInit {
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.changeColor(this.bg);
    this.apiService.buttonChanges.subscribe((element) => {
      if (element == true) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    })
  }

  @Input() text: string;
  @Input() imageSrc: string;
  @Input() bg: string = '#71B835';
  @Input() disabled: boolean;

  changeColor(color: string) {

    document.documentElement.style.setProperty("--bg-xeri-button", color);
  }
}
