import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";

@Component({
  selector: "app-app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  @Input() textHeader: string = "";
  @Input() imageNotification: string = "";
  @Input() imageArrow: string = "";
  @Input() arrowBack: boolean = false;
  @Input() showNotification: boolean = true;

  //Cuando se dispare este evento redirigo a la pagina que yo quiera
  //Realmente el string que emito no lo estoy usando en ningun lado
  @Output() onReturnPage: EventEmitter<string> = new EventEmitter();

  constructor(private router: Router, private navCtrl: NavController) {}

  ngOnInit() {}

  //Se dispara cuando hago clic en la flecha de volver atras
  //El objetivo es volver a la ruta correcta según en que página me encuentro actualmente
  returnToPage() {
    //Guardo la ruta actual
    const url = this.router.url;
    console.log(url);
    if (url === "/tabs/home") {
      this.onReturnPage.emit("returnHomePage");
    } else if (url === "/requests-professional") { 
      this.onReturnPage.emit("returnRequestProfessional");
    }else if (url === "/my-requests") {
      this.onReturnPage.emit("returnMyRequestsPage");
    } else {
      console.log("entra");
      this.navCtrl.back();
    }
  }
}
